@import '~@beewise/theme/build/variables';

.chart-container {
  position: relative;
  display: inline-block;
}

.custom-workspace-tooltip {
  border-radius: 4px;
  padding: 4px 12px;
  background-color: $dark-black;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $white;
  white-space: nowrap;
  min-width: fit-content;

  span {
    font-weight: 700;
    text-decoration: underline;
  }
}