@import '~@beewise/theme/build/variables';

.entity-marker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-out;
  z-index: 2;

  &:hover,
  &:has(.hovered),
  &:has(.selected) {
    z-index: 3;
  }

  :hover,
  &:has(.hovered) {

    .entity-label,
    .icon-wrapper .yard-marker-hives-number {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .icon-wrapper {
    position: absolute;
    top: -80%;
    background-color: $white;
    width: 32px;
    height: 24px;
    padding: 8px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $dark-black;
    box-sizing: border-box;

    .icon svg {
      path:first-of-type {
        fill: $black;
        stroke: $white;
        stroke-width: 2;
        stroke-opacity: .7;
      }

      path:nth-of-type(2n) {
        fill: $white;
        stroke: $black;
        stroke-opacity: 1;
        fill-opacity: 1;
      }
    }

    .yard-marker-hives-number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      line-height: 21px;
      font-weight: 500;
      color: $black;
      width: 100%;
      text-align: center;
    }
  }

  .entity-label {
    min-width: 30px;
    min-height: 30px;
    background: rgba(255, 255, 255, .7);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, .25);
    color: $black;
    border-radius: 52px;
    padding: 4px 6px;
    text-align: center;
    display: flex;
    align-items: center;
    backdrop-filter: blur(4px);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    box-sizing: border-box;
    justify-content: center;

    &.inspected.hovered {
      border: 2px solid $static-partition;
    }

    .chart-container {
      position: relative;
      display: inline-block;
      margin-right: 4px;
    }

    &.hovered {
      background-color: $white;
      font-size: 16px;
    }

    &:hover,
    &.selected-yard {
      background-color: $white;

      .entity-label-checkbox {
        margin-right: 4px;
        visibility: visible;
        height: 14px;
        width: 14px;
        transition: all 0.1s ease-out;
        color: $grey-6;
        display: block;
      }

      .entity-label-checkbox-unselected {
        visibility: visible;
        height: 14px;
        width: 14px;
        display: block;
        margin-right: 4px;
      }
    }

    &.clicked-entity {
      background-color: $white;
      color: $dark-black;

      .entity-label-checkbox {
        display: block;
        color: $stroke-blue;
        margin-right: 4px;
      }
    }

    &.selected {
      background-color: $white;
      color: $dark-black;

      .entity-label-checkbox {
        display: block;
        color: $stroke-blue;
        margin-right: 4px;

        &:hover {
          color: $grey-6;
        }
      }
    }

    &-checkbox {
      visibility: hidden;
      width: 0;
      transition: all 0.1s ease-out;
      display: none;

      &-unselected {
        visibility: hidden;
        height: 0;
        width: 0;
      }

      &:hover {
        .entity-label-checkbox-unselected {
          visibility: hidden;
          height: 0;
          width: 0;
          color: $grey-6;
          display: none;
        }

        .entity-label-checkbox-selected {
          visibility: visible;
          height: 14px;
          width: 14px;
          color: $grey-6;
          display: block;
        }
      }

      .hidden {
        visibility: hidden;
        height: 0;
        width: 0;
        display: none;
      }
    }

    .selected {
      visibility: visible;
      height: 14px;
      width: 14px;
      display: block;

      &.entity-label-checkbox {
        display: block;
        color: $stroke-blue;

        &:hover,
        .entity-label-checkbox-selected {
          color: $stroke-blue;
        }
      }
    }
  }
}

.yard-tooltip-wrapper.tooltip-content {
  padding: 0 !important;
  border-radius: 8px;
}

.entity-name {
  position: relative;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: $white;
  text-shadow:
    -1px -1px 0 $black,
    1px -1px 0 $black,
    -1px 1px 0 $black,
    1px 1px 0 $black;
}