@import '~@beewise/theme/build/variables';

$FILTER_WIDTH: 260px;
$DROPDOWN_WIDTH: 90px;

.left-panel {
  width: $FILTER_WIDTH;
  display: flex;
  flex-direction: column;

  &-header {
    padding: 16px 12px 0 12px;
    box-sizing: border-box;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    min-height: 44px;
    width: $FILTER_WIDTH;

    &-pin {
      font-size: 20px;
      color: $gray-08;
      cursor: pointer;
      padding: 4px;
      border-radius: 4px;

      &:hover {
        background-color: $gray-02;
      }

      &.is-active {
        background-color: $primary-light-blue;
        color: $primary-blue;
      }
    }

    &-block {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 8px;
      padding: 4px 0;

      &-title {
        font-weight: 700;
        font-size: 16px;
      }

      &-tooltip-text {
        text-align: start;
      }
    }
  }

  &-filter {
    padding: 16px;
    border-bottom: 1px solid $gray-50;

    &-block {
      .checkbox-label {
        font-size: 14px;
      }

      &-row {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }

      &-dropdown.select-field-root {
        width: 100%;
        min-width: $DROPDOWN_WIDTH;
        flex: 1;

        &.select-field-root .select-field__single-value {
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 12px;
        }

        .select-field__menu .select-field__option {
          font-size: 12px;
        }

        &:nth-of-type(2) {
          min-width: 120px;
          flex: 2;
        }

        .select-field__control {
          min-height: 28px;
          min-width: $DROPDOWN_WIDTH;
          width: 100%;
        }

        .select-field--normal .select-field__value-container {
          padding: 4px 8px;
        }
      }
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      width: 100%;
    }
  }
}