@import '~@beewise/theme/build/variables';

.drop-label {
    background: $white;
    box-shadow: 0 6px 8px 0 $light-black-shadow;
    color: $black;
    border-radius: 52px;
    padding: 3px 10px;
    text-align: center;
    display: flex;
    align-items: center;
    backdrop-filter: blur(4px);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    gap: 4px;

    .chart-container {
        position: relative;
        display: inline-block;
    }
}