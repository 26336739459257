@import '~@beewise/theme/build/variables';

$large-width: 34px;
$large-height: 38px;

.marker {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, .25));
    transition: all 0.1s ease-out;
    cursor: pointer;

    &-wrapper {
        position: relative;
        transform: translate(-50%, -50%);
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;


        &:has(.bhome-unassigned),
        &:has(.bhome-assigned) {
            z-index: 2;
        }

        &:hover {
            z-index: 3;
        }
    }

    &:not(.empty-location) {
        z-index: 1;
    }

    &-value {
        font-weight: 600;
        font-size: 12px;
        color: $grey-1;
        position: absolute;
    }

    .hex {
        position: relative;
    }

    .marker-alert {
        position: absolute;
        width: 28px;
        height: 28px;
        top: -4px;
        right: 0;
        transform: translateX(20%);
        z-index: 1;
        padding: 0;
        color: $red;

        &:hover {
            background-color: transparent;
        }
    }

    .tooltip-wrapper {
        .alert-icon {
            width: 22px;
            height: 22px;
        }
    }

    .secondary-marker-alert {
        transform: translateX(70%);
        z-index: unset;
    }

    &-large {
        svg {
            width: $large-width;
            height: $large-height;
        }
    }

    &.bhome-unassigned {
        width: 28px;
        height: 28px;
        border-radius: 88px;
        padding: 3px;
        box-sizing: border-box;
        background: $gray-95;
        border: .8px solid $gray-35;
        box-shadow: 0 4px 8px 0 $light-black-shadow;
        backdrop-filter: blur(4px);

        svg {
            width: 22px;
            height: 22px;
        }

        &:hover {
            background: $gray-90;
            box-shadow: 0px 6px 8px 0px $dark-black-shadow;
        }

        .marker-value {
            font-size: 10px;
            color: $white;
            top: 25%;
        }

    }

    &.bhome-assigned {
        width: 28px;
        height: 28px;
        border-radius: 88px;
        padding: 3px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 4px 8px 0 $light-black-shadow;
        backdrop-filter: blur(4px);

        svg {
            width: 22px;
            height: 22px;
        }

        &:hover {
            background: $white;
            box-shadow: 0 6px 8px 0 $dark-black-shadow;
        }

        .marker-value {
            color: $white;
            top: 25%;
        }
    }

    &.marker-large.bhome-unassigned {
        border-radius: 88px;
        box-sizing: border-box;
        padding: 4px 8px;
        background: $gray-95;
        border: 1px solid $white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
        backdrop-filter: blur(2px);
        width: 100%;
        height: 100%;

        .marker-value {
            top: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $white;
            white-space: nowrap;
            position: relative;
        }

        &:hover {
            background: $gray-90;
            backdrop-filter: blur(4px);
            box-shadow: 0 6px 8px 0 $dark-black-shadow;
        }
    }

    &.is-selected {
        width: 32px;
        height: 32px;

        svg {
            width: 24px;
            height: 24px;
        }

        .marker-value {
            top: 25%;
        }

    }

    &.is-selected.bhome-assigned {
        opacity: 100%;
        background: $blue-active;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .25);
        border: .8px solid $blue-border-active;
    }
}

@keyframes recentlyMovedBhomeSize {
    from {
        transform: scale(1.15);
    }

    to {
        transform: scale(1);
    }
}

@keyframes recentlyMovedBhomeColor {
    from {
        fill: $brand;
    }

    to {
        fill: $white;
    }
}

.marker-animated {
    &>.hex:first-child {
        animation: recentlyMovedBhomeSize .7s ease-in-out 0s;

        svg,
        path:last-child {
            animation: recentlyMovedBhomeColor .7s ease-in-out 0s;
        }
    }
}

.bhome-tooltip {
    font-size: 12px !important;
}